<template>
  <el-dialog  title="临时服务人员"
              :close-on-click-modal="false"
              :visible.sync="isOpenModal"
              append-to-body
              width="1000px"
              @closed="closeModal">
    <div style="width: 100%">
      <ch-button @click="openAddTag">新增</ch-button>
      <ch-table :render-option="tableOption" :data="tableList"
                :loading="isTableLoading"
                :props="{width: '100%', height: '300px', size: 'mini'}">
        <template v-slot:available="scope">
          <div v-if="scope.row.isAvailable===1" class="circle circle_green"></div>
          <div v-else class="circle circle_red"></div>
        </template>
        <template v-slot:handle="scope">
          <ch-button type="link" @click="openEdit(scope.row)">编辑</ch-button>
          <ch-button type="link" v-if="scope.row.status===0" @click="handleRecoverStop(scope.row)">停用</ch-button>
          <ch-button type="link" v-if="scope.row.status===1" @click="handleRecoverStop(scope.row)">恢复</ch-button>
          <ch-button type="delete" v-if="scope.row.canDelete===1" @click="handleDelete(scope.row)">删除</ch-button>
        </template>
      </ch-table>
    </div>
    <div slot="footer" class="flex_con">
      <ch-page
          type="old"
          ref="page"
          :getListFunction="getList"></ch-page>
    </div>
    <modal ref="tagsModal" :getListFunction="getList"></modal>
  </el-dialog>
</template>
<script>
import { handleDelete } from "@/utils/utils";

const modal = () => import("./modal.vue");
export default {
  components: {
    modal
  },
  data(){
    return {
      isOpenModal:false,
      isTableLoading:false,
      tableList:[],
    }
  },
  computed:{
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "姓名", prop: "name", showOverflowTooltip: true,},
        { column: "text", label: "手机号", prop: "phone", showOverflowTooltip: true,},
        { column: "text", label: "服务分成比例", prop: "_commissionRate", showOverflowTooltip: true,sign:'%'},
        { column: "text", label: "增值分成比例", prop: "_appreciationProportion", showOverflowTooltip: true,},
        { column: "slot", label: "服务状态", slotName: "available"},
        { column: "slot", label: "操作", slotName: "handle", width: "150",fixed: 'right'},
      ];
    },
  },
  methods:{
    open(){
      this.isOpenModal = true
      this.getList()
    },
    getList(){
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/serviceStaff/getTemporaryList").then((res) => {
        this.tableList = res.rows.map(item => {
          return {
            ...item,
            _commissionRate: item.commissionRate + '%',
            _appreciationProportion: item.appreciationProportion + '%',
          }
        })
      }).finally(() => {this.isTableLoading = false});
    },
    openAddTag(){
      this.$refs?.tagsModal?.openAdd()
    },
    openEdit(row){
      this.$refs?.tagsModal?.openEdit(row)
    },
    handleDelete(row){
      handleDelete("/hm/hmServiceStaffTagLibrary/delete/", row.id).then(() => {
        this.getList()
      })
    },
    // 恢复停用
    handleRecoverStop(row) {
      let text = row.status===0? '停服':'恢复'
      this.$confirm(`${text}本服务人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let status = row.status===0? 1:0
        this.$curl.post(`/hm/serviceStaff/updateStatus/${row.id}/${status}`)
            .then(() => {
              this.$message.success("操作成功！");
              this.getList()
            })
      })
    },
    closeModal(){
      this.isOpenModal = false
    }
  }
}
</script>
<style scoped lang="scss">
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 20px;
}
.circle_green{
  background: #00ff00;
}
.circle_red{
  background: #ff0000;
}
</style>